import { ref } from "vue";
import { storeToRefs } from "pinia";
import { useApi } from "@/use/api/useApi";
import { useSuperAccount } from "@/use/superAccount/useSuperAccount";
import { applyFullNameToUser } from "@/utils/formatter/nameFormatter";
import { ROLE_READ_WRITE, TM_MANAGER } from "@/const/roles";

import type { BaseObjectI } from "@/stores/manuals/ManualsInterface";
import type { AdminI, InviteUserI, ManagerI, SimpleManagerI } from "@/stores/auth/UserInterface";

import useManualsStore from "@/stores/manuals/useManualsStore";
import useNotificationsStore from "@/stores/systemNotifications/useNotificationsStore";
import useUsersStore from "@/stores/users/useUsersStore";
import useTmManagerStore from "@/stores/tenmonManager/useTmManagerStore";
import useAuthStore from "@/stores/auth/useAuthStore";

export function getErrorMessage(code: number, error: any, defaultMessage = 'Произошла ошибка, попробуйте позднее') {
  switch(code) {
    case 403:
    case 412:
      return error?.message || error?.detail || defaultMessage;
    default:
      return defaultMessage;
  }
}

export function useUserModifier(isCreate: boolean, isAdminMode = false, initialData?: ManagerI) {

  const { assignResourceId } = useSuperAccount();
  const { showSuccess } = useNotificationsStore();

  const manualsStore = useManualsStore();
  const {
    usersRolesList,
    adminsRolesList,
  } = storeToRefs(manualsStore);

  const authStore = useAuthStore();
  const { me } = storeToRefs(authStore);

  const usersStore = useUsersStore();
  const { usersList } = storeToRefs(usersStore);

  const managerStore = useTmManagerStore();
  const { adminsList } = storeToRefs(managerStore);

  function onInviteUser(user: ManagerI) {
    if (isAdminMode) adminsList.value.push(user)
    else usersList.value.push(user)
  }

  function onUpdateAdmin(admin: AdminI) {
    const index = adminsList.value.findIndex(e => e.id === admin.id)
    if (index !== -1) adminsList.value[index] = admin
  }

  function onUpdateUser(user: ManagerI) {
    const index = usersList.value.findIndex(e => e.id === user.id)
    if (index !== -1) usersList.value[index] = user
  }

  function onUpdateMe(user: ManagerI) {
    if (me.value.id === user.id) me.value = user
  }

  function getRole(): BaseObjectI|undefined {
    if (!isCreate) return initialData?.role ?? undefined

    if (isAdminMode) return adminsRolesList.value.find(e => e.id === TM_MANAGER)
    else return usersRolesList.value.find(e => e.id === ROLE_READ_WRITE)
  }

  function getForm(): InviteUserI {
    return {
      id: isCreate ? null : initialData?.id,
      surName: isCreate ? '' : initialData?.surName || '',
      firstName: isCreate ? '' : initialData?.firstName || '',
      middleName: isCreate ? '' : initialData?.middleName || '',
      login: isCreate ? '' : initialData?.login || '',
      phone: isAdminMode ? undefined : (isCreate ? '' : initialData?.phone || ''),
      timezone: initialData?.timezone || initialData?.timezone === 0 ? initialData?.timezone : null,
      role: getRole(),
    }
  }

  const form = ref<InviteUserI>(getForm());

  function resetForm() {
    form.value = getForm()
  }

  function getLabel() {
    if (isCreate) return 'Добавить нового пользователя';
    else return `Редактирование данных пользователя ${ initialData?.fio }`
  }

  /**
   * Редактирование пользователя
   * INNER USE
   */
  function editUserInfo() {

    const payload = {
      firstName: form.value?.firstName || '',
      middleName: form.value?.middleName || '',
      surName: form.value?.surName || '',
      timezone: form.value?.timezone || form.value?.timezone === 0 ? form.value?.timezone : undefined,
      phone: form.value?.phone || undefined,
      role: form.value?.role ? `/api/roles/${form.value?.role?.id}` : undefined,
    }

    return new Promise((resolve, reject) => {
      useApi().users.patchUser<ManagerI>(form.value?.id, payload)
        .then((data) => {

          const user = applyFullNameToUser(data)
          onUpdateUser(user)
          onUpdateMe(user)

          showSuccess()
          resolve(user)
        })
        .catch((error) => reject(getErrorMessage(error?.response?.status, error?.data, 'Произошла ошибка при обновлении данных о пользователе, попробуйте позже')))
    })
  }

  /**
   * Редактирование админа
   * INNER USE
   */
  function editAdminInfo() {

    const payload = {
      firstName: form.value?.firstName || '',
      middleName: form.value?.middleName || '',
      surName: form.value?.surName || '',
      role: form.value?.role ? `/api/admins-roles/${form.value?.role?.id}` : undefined,
    }

    return new Promise((resolve, reject) => {
      useApi().admin.patchAdmin<AdminI>(form.value?.id, payload)
        .then((data) => {

          const admin = applyFullNameToUser(data)
          onUpdateAdmin(admin)
          onUpdateMe(admin)

          showSuccess()
          resolve(admin)
        })
        .catch((error) => reject(getErrorMessage(error?.response?.status, error?.data)))
    })
  }

  /**
   * Приглашение пользователя/создание админа
   * INNER USE
   */
  function inviteUser() {

    const payload = assignResourceId({
      firstName: form.value?.firstName || '',
      middleName: form.value?.middleName || '',
      surName: form.value?.surName || '',
      login: form.value?.login || undefined,
      phone: form.value?.phone || undefined,
      role: form.value?.role ? `/api/${ isAdminMode ? 'admins-roles' : 'roles' }/${ form.value?.role?.id }` : undefined,
    })

    return new Promise((resolve, reject) => {
      (isAdminMode
        ? useApi().admin.postAdmin<AdminI>(payload)
        : useApi().users.postUser<ManagerI>(payload))
          .then((data) => {
            const user = applyFullNameToUser(data)
            showSuccess(isAdminMode ? 'Менеджер приглашен' : 'Пользователь приглашен')
            onInviteUser(user)
            resolve(user)
          })
          .catch((error) => reject(getErrorMessage(error?.response?.status, error?.data)))
    })
  }

  function saveForm() {
    if (isCreate) return inviteUser()
    else {
      if (isAdminMode) return editAdminInfo()
      else return editUserInfo()
    }
  }

  return {
    form,
    usersRolesList,
    adminsRolesList,
    getLabel,
    saveForm,
    resetForm,
  }
}
